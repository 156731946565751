<template>
  <div class="row">
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='CenterSklad').length>0}">
       <span  v-if="ozonInfo.filter(e => e.supl=='CenterSklad').length>0">
         <span
             title="Цена на озоне (доход) | остаток озон, остаток у нас"
             v-for="item of ozonInfo.filter(e => e.supl=='CenterSklad')" :key="item.offer_id">
          <a :href="`https://www.ozon.ru/product/${item.info.sources.find(e => e.source=='fbo').sku}/`" target="_blank">
            {{+item.info.price}} ({{item.dohod}}) | <span>{{ item.info.stocks.present }}</span> <span>{{item.export_balance+item.balance}}</span>
          </a>
         </span>
      </span>
      <span v-else>нет на сайте </span>
    </div>
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='Neo').length>0}">
      <span  v-if="ozonInfo.filter(e => e.supl=='Neo').length>0">
         <span
             title="Цена на озоне (доход) | остаток озон, остаток у нас"
             v-for="item of ozonInfo.filter(e => e.supl=='Neo')" :key="item.offer_id">
          <a :href="`https://www.ozon.ru/product/${item.info.sources.find(e => e.source=='fbo').sku}/`" target="_blank">
            {{+item.info.price}} ({{item.dohod}}) | <span>{{ item.info.stocks.present }}</span> <span>{{item.export_balance+item.balance}}</span>
          </a>
         </span>
      </span>
      <span v-else>нет </span>
    </div>
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='Cooper').length>0}">
       <span  v-if="ozonInfo.filter(e => e.supl=='Cooper').length>0">
         <span
             title="Цена на озоне (доход) | остаток озон, остаток у нас"
             v-for="item of ozonInfo.filter(e => e.supl=='Cooper')" :key="item.offer_id">
          <a :href="`https://www.ozon.ru/product/${item.info.sources.find(e => e.source=='fbo').sku}/`" target="_blank">
            {{+item.info.price}} ({{item.dohod}}) | <span>{{ item.info.stocks.present }}</span> <span>{{item.export_balance+item.balance}}</span>
          </a>
         </span>
      </span>
      <span v-else>нет</span>
    </div>
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='Alcon').length>0}">
      <span  v-if="ozonInfo.filter(e => e.supl=='Alcon').length>0">
         <span
             title="Цена на озоне (доход) | остаток озон, остаток у нас"
             v-for="item of ozonInfo.filter(e => e.supl=='Alcon')" :key="item.offer_id">
          <a :href="`https://www.ozon.ru/product/${item.info.sources.find(e => e.source=='fbo').sku}/`" target="_blank">
            {{+item.info.price}} ({{item.dohod}}) | <span>{{ item.info.stocks.present }}</span> <span>{{item.export_balance+item.balance}}</span>
          </a>
         </span>
      </span>
      <span v-else>нет</span>
    </div>
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='Eye').length>0}">
      <span  v-if="ozonInfo.filter(e => e.supl=='Eye').length>0">
         <span
             title="Цена на озоне (доход) | остаток озон, остаток у нас"
             v-for="item of ozonInfo.filter(e => e.supl=='Eye')" :key="item.offer_id">
          <a :href="`https://www.ozon.ru/product/${item.info.sources.find(e => e.source=='fbo').sku}/`" target="_blank">
            {{+item.info.price}} ({{item.dohod}}) | <span>{{ item.info.stocks.present }}</span> <span>{{item.export_balance+item.balance}}</span>
          </a>
         </span>
      </span>
      <span v-else>нет </span>
    </div>
<!--  </div>-->

  </div>
</template>

<script>
export default {
  props: ["barcode", "item"],
  name: 'infobarcode-ozon',
  data: () => ({
    isLoading: false,
    error: '',
    ozonInfo: []
  }),
  methods: {},
  async mounted() {
    this.isLoading = true
    try {
      const data = await fetch(`${process.env.VUE_APP_SERVER_API}/report/ozonostatki/${this.barcode}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'dildo-token': this.$cookies.get("dildo-token")
        }
      })
      this.ozonInfo = await data.json()

      this.isLoading = false
    } catch (err) {
      this.error = err
    }
  },
};
</script>

<style scoped>
/*.not-found {*/
/*  background-color: rgba(220, 53, 69, 0.3) !important;*/
/*}*/
/*.normal {*/
/*  background-color: rgba(25, 135, 84, 0.3) !important;*/
/*}*/

</style>
